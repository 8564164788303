<template>
  <div class="account-wrap">
    <div class="item-card df-styles">
      <van-form>
      <van-tabs @change="changeTab" v-model="active" title-active-color="var(--line-gradient-start-color)">
        <van-tab>
          <div slot="title" class="font16">
            {{$t('auth.email')}}
          </div>
          <div class="email-ipt margin-top16">
            <van-field
              v-model="auth.email"
              :rules="[{ validator: emailRuls, trigger: 'onChange'}]"
            />
          </div>
          <p v-if="!validateStatus.email" class="error-box main-input-error font12">{{ $t('auth.emialErrorTip') }}</p>
        </van-tab>
        <van-tab>
          <div slot="title" class="font16">
            {{$t('auth.phone')}}
          </div>
          <div class="phone-ipt margin-top16" :class="{'van-field--error': !validateStatus.phone}">
            <van-field
              v-model="auth.phone"
              :rules="[{ validator: phoneRuls, trigger: 'onChange'}]"
              type="digit"
            >
            <template #label>
              <Country @callback="countryCallback"></Country>
            </template>
            </van-field>
          </div>
          <p v-if="!validateStatus.phone" class="error-box main-input-error font12">{{ $t('auth.phoneErrorTip') }}</p>
        </van-tab>
      </van-tabs>
      </van-form>
    </div>
  </div>
</template>

<script>
import Country from '@/components/auth/country/index.vue'
import Vue from 'vue'
import 'vant/lib/index.css'
import 'lib-flexible/flexible'
import { Field, Row, Col, Icon, Tab, Tabs } from 'vant'

import { numberReg } from '@/constant/reg.js'

Vue.use(Row)
Vue.use(Col)
Vue.use(Field)
Vue.use(Icon)
Vue.use(Tab).use(Tabs)

export default {
  components: { Country },
  computed: {
  },
  data () {
    return {
      active: '0',
      accountStatus: 'email',
      countryObj: {},
      auth: {
        phone: '',
        email: '',
        mobile_code: ''
      },
      validateStatus: {
        phone: true,
        email: true
      }
    }
  },
  methods: {
    changeTab () {
      const statusArr = ['email', 'phone']
      this.accountStatus = statusArr[this.active]
      const accountObj = {
        accountStatus: this.accountStatus,
        ...this.auth
      }
      this.$emit('callback', accountObj)
    },
    countryCallback (value) {
      console.log(value)
      this.countryObj = value
    },
    validateAccountFn (account) {
      const checkAccount = {
        phone: () => {
          const reg = numberReg
          return reg.test(account)
        },
        email: () => {
          const reg = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
          return reg.test(account)
        }
      }
      const checkFn = checkAccount[this.accountStatus]
      return checkFn()
    },
    blurEmailFn () {
      const accountObj = {
        accountStatus: this.accountStatus,
        phone: '',
        email: '',
        mobile_code: ''
      }
      this.validateStatus.email = this.validateAccountFn(this.auth[this.accountStatus])
      if (this.validateStatus.email) {
        accountObj.email = this.auth[this.accountStatus]
        this.$store.dispatch('updateEmail', accountObj.email)
        this.$emit('callback', accountObj)
      } else {
        this.$emit('callback', accountObj)
      }
    },
    blurPhoneFn () {
      const accountObj = {
        accountStatus: this.accountStatus,
        phone: '',
        email: '',
        mobile_code: ''
      }
      this.validateStatus.phone = this.validateAccountFn(this.auth[this.accountStatus])
      if (this.validateStatus.phone) {
        accountObj.phone = this.auth[this.accountStatus]
        accountObj.mobile_code = this.countryObj.mobile_code
        this.$store.dispatch('updateMobileCode', accountObj.mobile_code)
        this.$store.dispatch('updatePhone', accountObj.phone)
        this.$emit('callback', accountObj)
      } else {
        this.$emit('callback', accountObj)
      }
    },
    // 异步校验函数返回 Promise
    emailRuls (val) {
      return new Promise((resolve) => {
        const tVal = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(val)
        this.blurEmailFn()
        resolve(tVal)
      })
    },
    phoneRuls (val) {
      return new Promise((resolve) => {
        const tVal = numberReg.test(val)
        this.blurPhoneFn()
        resolve(tVal)
      })
    }
  }
}
</script>

<style lang="less">
.verification-wrap{
  box-sizing: border-box;
  width: 100%;
}
.item-card{
  .van-tab{
    flex:none;
    margin-right: 48px;
  }
  .van-tabs__line{
    background-color: var(--line-gradient-start-color);
  }
}
.btn-box{
  width: 100%;
  .van-button{
    width: 80%;
    margin-left: 10%;
  }
}
</style>
