<template>
  <Wrapper :notice="$t('auth.resetPassWord')" :headerLeftStatus="'leftArrow'">
    <div>
      <Account @callback="accountCallack"></Account>
      <div class="btn-box margin-top20">
        <van-button type="info" @click="checkAccount" :disabled="resetDisabled">{{$t('next')}}</van-button>
      </div>
    </div>
  </Wrapper>
</template>

<script>
import Wrapper from '@/components/auth/wrapper/index.vue'
import Account from '@/components/auth/account/index.vue'
import { NavBar, Button } from 'vant'
import Vue from 'vue'
import { mapActions } from 'vuex'

import { sendEmailSms } from '@/api/auth.js'

Vue.use(NavBar)
Vue.use(Button)

export default {
  name: 'ResetPassWord',
  components: {
    Account, Wrapper
  },
  computed: {
    resetDisabled  () {
      const getStatusObj = {
        phone: () => {
          return !(this.auth.phone)
        },
        email: () => {
          return !(this.auth.email)
        }
      }
      const status = getStatusObj[this.accountStatus]()
      return status
    }
  },
  data () {
    return {
      isComponment: 'SignUp',
      accountStatus: 'phone',
      auth: {
        phone: '',
        email: '',
        mobile_code: ''
      }
    }
  },
  methods: {
    accountCallack (value) {
      console.log(value)
      this.accountStatus = value.accountStatus
      this.auth.phone = value.phone
      this.auth.email = value.email
      this.auth.mobile_code = value.mobile_code
    },
    checkAccount () {
      // this.$notify(this.$t('auth.loginError'))
      console.log('checkAuth----resetPassword')
      this.goVerification()
    },
    goVerification () {
      const params = {
        email: this.auth.email
      }
      this.updatePhone('')
      if (this.accountStatus === 'phone') {
        this.updateEmail('')
        delete params.email
        params.mobile = this.auth.phone
        params.mobile_code = this.auth.mobile_code
        this.updatePhone(this.auth.phone)
      }
      sendEmailSms(params).then(res => {
        if (res.code === 0 && res.data.account_status) {
          const resData = res.data
          this.$store.dispatch('updateVerificationAccountStatus', resData.account_status)
          console.log(resData.expires_time, 'resData.expires_time')
          this.$store.dispatch('updateVerificationCountdown', 60)
          // resData.expires_time
          this.$store.dispatch('updateVerificationExpiresTime', new Date())
          this.$router.push('/register/verification/resetPwd')
          this.$toast.clear()
        } else {
          this.$toast.fail(this.$t('nav.userNoExist'))
        }
      })
    },
    ...mapActions(['updateEmail', 'updatePhone'])
  }
}
</script>

<style lang="less">
.resetPassWord-wrap{
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}
.btn-box{
  box-sizing: border-box;
  width: 100%;
  .van-button{
    width: 100%;
    margin-left: 0%;
    background-image: linear-gradient(to right, #5B21CB,#FA9BC3);
  }
  .van-button--info {
    border: none;
  }
}
</style>
